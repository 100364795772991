import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import FiestaImg from "../assets/01.jpg"
import { StaticImage } from "gatsby-plugin-image"

const Homepage = ({ data, location }) => {
  console.log(data, location)

  return (
    <Layout location={location} title={"Doma"}>
      <Seo title="Moje úvodní stránka" location={location} />

      <section className="" id="kdo-jsem">
        <div className="">
          <div className="c-fig-text">
            <div className="c-fig-text__right c-fig-text__right--sec">
              <div className="c-fig-text__content">
                <h2 className="o-type-4">Kdo jsem</h2>
                <p>
                  Do světa financí jsem přišel před téměř 10 lety. Měl jsem
                  pouze základní znalosti, a to ze svých dosavadních zkušeností
                  s pojištěním a stavebním spořením. Velmi brzy jsem pochopil, a
                  to se snažím ukázat i svým klientům, že o peníze stejně jako o
                  naše zdraví, majetek či vztahy, je dobré se starat a myslet
                  hodně dopředu. Neustálé vzdělávání a posouvání se vpřed a být
                  lepší pro své klienty a konec konců i pro sebe mi dává tolik
                  potřebnou sílu a motivaci.
                </p>
                <p>Řídím se heslem od Lese Browna:</p>
                <blockquote>
                  <p>
                    <i className="demo-icon icon-quote-left"></i>
                    <strong className="o-type-2">
                      Miřte na měsíc! I když jej minete, skončíte mezi hvězdami.
                    </strong>
                    <i className="demo-icon icon-quote-right"></i>
                  </p>
                </blockquote>
              </div>
            </div>
            <div className="c-fig-text__left">
              <figure className="swiper-slide">
                <StaticImage
                  src="../images/radek-2-kancl-1-1024x683.jpg"
                  alt="Radek Topolčány realitní maklér"
                />
              </figure>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Homepage
